<template>
  <div>
    <Years @year="changeYear($event)">
      <router-link to="/dashboard/addquestionsform" class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Ajouter une question</router-link>
    </Years>
  </div>
</template>
 
<script>
import Years from '../dashboard/Years.vue'
export default {
  components:{
    Years
  },

  data () {
     return {
        currentRoute: window.location.pathname
     }
   },

  props:{
    linkPath:Function,
  },

  mounted () {
    this.linkPath(this.currentRoute)
  },

  methods:{
    changeYear(year){
      this.$router.push("/dashboard/questionsList/?year="+year);
    }
  }
}
</script>

<style>

</style>